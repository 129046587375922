import React from 'react';
import Wording from '../components/Landing/Wording';
import Banner from '../components/Landing/Banner';
import Slice from '../components/Landing/Slice'
import InsideCrestx from '../components/Landing/InsideCrestx'
import TradingJourney from '../components/Landing/TradingJourney'
import ReferCrestsX from '../components/Landing/ReferCrestsX'
import Faq from '../components/Landing/Faq'
import StartStep from '../components/Landing/StartStep'

const Landing_page = () => {
    return (
        <>
            <Wording />
            <Banner />
            <Slice />
            <InsideCrestx />
            <TradingJourney />
            <ReferCrestsX />
            <Faq />
            <StartStep />
        </>
    );
};

export default Landing_page;