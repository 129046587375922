import React from 'react';
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react';

const StartStep = () => {
  return (
    <Box bg="#03091C" py={10} px={6} display="flex" justifyContent="center">
      <Box
        bgGradient="radial-gradient(circle, #1A2F7D, #040711)"
        bgImage="/Banner/First_step.svg"
        borderRadius="lg"
        p={8}
        border="1px solid"
        borderColor="rgba(158, 202, 255, 0.5)"
        minW='80%'
        maxW="lg"
        textAlign="right"
      >
        <Heading as="h2" size="lg" mb={4} color="white" fontFamily="Poppins">
          Start your first step with us
        </Heading>
        <Text fontSize="md" color="rgba(158, 202, 255, 0.8)" mb={6}>
          Deposit your funds to earn yield
        </Text>
        <Button colorScheme="#0A0E1B" variant="solid" border="1px solid" borderColor="rgba(158, 202, 255, 0.5)">
          Trade now
        </Button>
      </Box>
    </Box>
  );
};

export default StartStep;