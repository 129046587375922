import React from 'react';
import styles from './Footer.module.css';
import Logo from '../../assets/CrestsXLogo.svg';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src={Logo} alt="Logo" className={styles.logo} />
        </div>
        <div className={styles.linksContainer}>
          <div className={styles.column}>
            <h4>About</h4>
            <a href="#gitbook">Gitbook</a>
            <a href="#whitepaper">Whitepaper</a>
            <a href="#faq">FAQ</a>
            <a href="#blog">Blog</a>
          </div>
          <div className={styles.column}>
            <h4>Product</h4>
            <a href="#spot-trade">Spot trade</a>
            <a href="#referral">Referral</a>
            <a href="#campaign">Campaign</a>
            <a href="#portfolio">Portfolio</a>
          </div>
          <div className={styles.column}>
            <h4>Resource</h4>
            <a href="#learning-center">Learning center</a>
            <a href="#media-kit">Media kit</a>
            <a href="#api-doc">API doc</a>
          </div>
          <div className={styles.column}>
            <h4>Community</h4>
            <a href="#link3">Link3</a>
            <a href="#x">X</a>
            <a href="#medium">Medium</a>
            <a href="#discord">Discord</a>
            <a href="#telegram">Telegram</a>
          </div>
        </div>
        {/* <div className={styles.newsletter}>
          <h4>Newsletter</h4>
          <div className={styles.newsletterInput}>
            <input type="email" placeholder="Your Email" />
            <button>→</button>
          </div>
        </div> */}
      </div>
      <div className={styles.footerBottom}>
        {/* <div className={styles.socialMedia}>
          <a href="#email" className={styles.icon}>✉️</a>
          <a href="#linkedin" className={styles.icon}>🔗</a>
          <a href="#telegram" className={styles.icon}>✈️</a>
          <a href="#discord" className={styles.icon}>🎮</a>
          <a href="#facebook" className={styles.icon}>📘</a>
        </div> */}
        <p>Copyright © 2023 CrestsX. All rights reserved.</p>
        <div className={styles.terms}>
          <a href="#terms-of-use">Terms of use</a>
          <a href="#terms-of-service">Term of service</a>
          <a href="#privacy-policy">Privacy policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;