import React, { useState } from 'react';
import { 
  Box, 
  Flex, 
  Heading, 
  Text, 
  Button, 
  VStack, 
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  AccordionPanel, 
  AccordionIcon, 
  useBreakpointValue,
  Image 
} from '@chakra-ui/react';

const options = [
  {
    label: 'Spot',
    title: 'Spot trading',
    description: 'Take control of your crypto trades with CrestsX Spot Trade Orderbook – eliminate slippage, trade securely, and efficiently!',
  },
  {
    label: 'Derivative Warrants',
    title: 'Derivative Warrants',
    description: 'Trade derivative warrants with high precision and low latency on CrestsX.',
  },
  {
    label: 'Derivative Perpetual',
    title: 'Derivative Perpetual',
    description: 'Engage in perpetual derivative trading with CrestsX for seamless experience.',
  },
  {
    label: 'Copy Trade',
    title: 'Copy Trade',
    description: 'Copy the trades of successful traders and improve your trading strategy with CrestsX.',
  },
  {
    label: 'NFT Orderbook',
    title: 'NFT Orderbook',
    description: 'Trade NFTs efficiently with CrestsX NFT Orderbook.',
  },
  {
    label: 'LP leveraging system',
    title: 'LP leveraging system',
    description: 'Leverage liquidity provider token from other platform and earn on top',
  },
];

const InsideCrestx = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bgImage="/Background/BG_circle.svg" bgSize="auto" color="white" py={10} px={6}>
      <Heading as="h2" size="lg" mb={6} textAlign="center" fontFamily="Poppins">
        What's inside CRESTX?
      </Heading>
      {isMobile ? (
        <Accordion allowToggle>
          {options.map((option, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {option.label}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Heading as="h3" size="md" mb={4} fontFamily="Poppins">
                  {option.title}
                </Heading>
                <Text mb={4}>{option.description}</Text>
                <Box w="full" h="200px" bg="gray.700" mb={4}>
                  <Image src='/inside-CRESTX.svg' 
                    h='200'
                    w='full'
                  />
                </Box> 
                <Flex justifyContent="space-between">
                  <Button colorScheme="blue" variant="outline">
                    Start Trading
                  </Button>
                  <Button colorScheme="blue" variant="ghost">
                    Learn more
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <Flex justifyContent="center" mx="10%">
          <VStack spacing={4} align="stretch" w="30%">
            {options.map((option, index) => (
              <Button
                key={index}
                variant="ghost"
                color={index === selectedIndex ? 'white' : 'rgba(158, 202, 255, 0.8)'}
                bg={index === selectedIndex ? 'rgba(158, 202, 255, 0.2)' : 'transparent'}
                _hover={{ bg: 'rgba(158, 202, 255, 0.2)' }}
                onClick={() => setSelectedIndex(index)}
                borderRadius="none"
                justifyContent="flex-start"
              >
                {option.label}
              </Button>
            ))}
          </VStack>
          <Box bg="#1E2A38" borderRadius="lg" p={6} ml={6} w="60%">
            <Heading as="h3" size="md" mb={4} fontFamily="Poppins">
              {options[selectedIndex].title}
            </Heading>
            <Text mb={4}>{options[selectedIndex].description}</Text>
            <Box w="full" h="200px"  mb={4}>
              <Image 
                src='/inside-CRESTX.svg'
                h='200'
                w='full' />
            </Box> 
            <Flex justifyContent="space-evenly">
              <Button colorScheme="blue" variant="outline">
                Start Trading
              </Button>
              <Button colorScheme="blue" variant="outline">
                Learn more
              </Button>
            </Flex>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default InsideCrestx;