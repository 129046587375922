import React from 'react';
import { Flex, Box, Image, Text } from '@chakra-ui/react';

const Banner = () => {
    // Content for the boxes
    const leftBoxesContent = [
        { title: 'Your key Your coin', description: 'Your assets, your control. No intermediaries.' },
        { title: 'Trade Seamlessly', description: 'Fast and efficient trading experience at your fingertips.' },
        { title: 'Permissionless Listing', description: 'Seamlessly list assets without gatekeeping.' },
    ];

    const rightBoxesContent = [
        { title: 'Low Fees', description: 'Trade with minimal fees and maximum transparency.' },
        { title: 'Liquidity Incentive', description: 'Earn interest as a reward for providing liquidity.' },
        { title: 'Future of Trading', description: 'Join the revolution and experience decentralized trading.' },
    ];

    return (
        <Box bgColor="#03091C" p={6}>
            <Flex
                align="center"
                justify="space-between"
                mb={6}
                mx="5%"
                direction={['column', 'column', 'row']} // Column on small screens, row on larger screens
            >
                {/* Left Column */}
                <Flex
                    direction="column"
                    justify="space-between"
                    mt="12%"
                    mb={[6, 6, 0]} // Adds margin bottom on small screens
                    w={['100%', '100%', '25%']} // Full width on small screens, 25% on larger screens
                    display={['none', 'none', 'flex']} // Hide on small screens, visible on medium and larger screens
                >
                    {leftBoxesContent.map((box, idx) => (
                        <Box
                            key={idx}
                            // bg="#141E3E"
                            border="1px solid #333"
                            borderRadius="lg"
                            boxShadow="lg"
                            textAlign="center"
                            mb={4}
                            color="white"
                            position="relative"
                            _before={{
                                content: '""',
                                display: 'block',
                                paddingBottom: '56.25%', // 16:9 aspect ratio
                            }}
                            w="100%"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                left="0"
                                right="0"
                                bottom="0"
                                p={4}
                            >
                                <Text fontSize={{sm:'10px', md:'14px', lg:'18px'}} fontWeight="bold" fontFamily="Poppins">
                                    {box.title}
                                </Text>
                                <Text fontSize={{sm:'8px', md:'10px', lg:'12px'}} mt={2} fontFamily="Poppins">
                                    {box.description}
                                </Text>
                            </Box>
                        </Box>
                    ))}
                </Flex>

                {/* Image */}
                <Image
                    src="/Banner/mascos_trade.svg"
                    mb={[6, 6, 0]} // Adds margin bottom for small screens
                    w={['100%', '80%', '50%']} // Full width on small, 80% on medium, 50% on large screens
                    mx="8%"
                />

                {/* Right Column */}
                <Flex
                    direction="column"
                    justify="space-between"
                    mt="12%"
                    w={['100%', '100%', '25%']} // Full width on small screens, 25% on larger screens
                    display={['none', 'none', 'flex']} // Hide on small screens, visible on medium and larger screens
                >
                    {rightBoxesContent.map((box, idx) => (
                        <Box
                            key={idx}
                            // bg="#141E3E"
                            borderRadius="lg"
                            border="1px solid #333"
                            boxShadow="lg"
                            textAlign="center"
                            mb={4}
                            color="white"
                            position="relative"
                            _before={{
                                content: '""',
                                display: 'block',
                                paddingBottom: '56.25%', // 16:9 aspect ratio
                            }}
                            w="100%"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                left="0"
                                right="0"
                                bottom="0"
                                p={4}
                            >
                                <Text fontSize={{sm:'10px', md:'14px', lg:'18px'}} fontWeight="bold" fontFamily="Poppins">
                                    {box.title}
                                </Text>
                                <Text fontSize={{sm:'8px', md:'10px', lg:'12px'}} mt={2} fontFamily="Poppins">
                                    {box.description}
                                </Text>
                            </Box>
                        </Box>
                    ))}
                </Flex>
            </Flex>
        </Box>
    );
};

export default Banner;