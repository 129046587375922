import React from 'react';
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react';

const ReferCrestsX = () => {
  return (
    <Box bg="#03091C" py={10} px={6} display="flex" justifyContent="center" >
      <Box
        bgGradient="radial-gradient(circle, #1A2F7D, #040711)"
        // bgImage="/Banner/First_step.svg"
        borderRadius="lg"
        p={8}
        border="1px solid"
        borderColor="rgba(158, 202, 255, 0.5)"
        minW='80%'
        maxW="lg"
        textAlign="right"
      >
        {/* <Box
          textAlign="left"
        > */}
          <Heading as="h2" size="lg" mb={4} color="white" fontFamily="Poppins">
            Refer CrestsX earn commission
          </Heading>
          <Text fontSize="md" color="rgba(158, 202, 255, 0.8)" mb={4}>
            Share CrestsX to your friends
          </Text>
          <Text fontSize="md" color="rgba(158, 202, 255, 0.8)" mb={6}>
            Earn portion of trading fees
          </Text>
          <Button colorScheme="#0A0E1B" variant="solid" border="1px solid" borderColor="rgba(158, 202, 255, 0.5)">
            Learn more
          </Button>
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default ReferCrestsX;