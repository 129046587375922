import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Heading, Link, Text, Image, Flex, Button,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { slides } from '../../contents/slidesData';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Slice = () => {
  const [slider, setSlider] = React.useState<Slider | null>(null)
  const navigate = useNavigate();

  const handleSlideClick = (id: string) => {
    navigate(`/article/${id}`);
  };

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' })
  const side = useBreakpointValue({ base: '30%', md: '40px' })

  // Settings for the slider
  var settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    dots: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Box bg="#03091C" color="white" py={10} px={6}>
      <Flex justify="space-between" align="center" mb={6} mx="10%">
        <Heading as="h2" size="lg" fontFamily="Poppins">
          CrestsX Update
        </Heading>
        <Link color="rgba(158, 202, 255, 0.8)" href="/article">
          View all
        </Link>
      </Flex>
      <Text fontSize="lg" color="rgba(255, 255, 255, 0.7)" mb={6} mx="10%">
        Get to know what are we up to &gt;&gt;
      </Text>
      <Flex overflow="hidden" pb={4} mx="10%" justifyContent="center">
        <Box position={'relative'} height={'300px'} width={'80%'} overflow={'hidden'}>
          {/* CSS files for react-slick */}
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          {/* Left Icon */}
          <IconButton
            aria-label="left-arrow"
            variant="ghost"
            position="absolute"
            left={side}
            top={top}
            transform={'translate(0%, -50%)'}
            zIndex={2}
            onClick={() => slider?.slickPrev()}>
            <BiLeftArrowAlt size="40px" />
          </IconButton>
          {/* Right Icon */}
          <IconButton
            aria-label="right-arrow"
            variant="ghost"
            position="absolute"
            right={side}
            top={top}
            transform={'translate(0%, -50%)'}
            zIndex={2}
            onClick={() => slider?.slickNext()}>
            <BiRightArrowAlt size="40px" />
          </IconButton>
          {/* Slider */}
          <Slider {...settings} ref={(slider) => setSlider(slider)}>
            {slides.map((slides, index) => (
              <Box
                key={index}
                height={300}
                position="relative"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="90%" 
                backgroundImage={slides.image}
                onClick={() => handleSlideClick(slides.id)}
                // mx={50}
                >
              </Box>
            ))}
          </Slider>
        </Box>
      </Flex>
    </Box>
  );
};

export default Slice;