import React from 'react';
import { Box, Heading, Text, Button, Stack, SimpleGrid, GridItem } from '@chakra-ui/react';

const Wording = () => {
  return (
    <Box
      bg="#03091C"
      bgImage="url('/Background/background.svg')"
      bgSize="cover"
      bgPosition="center"
      display="flex"
      justifyContent="left"
      alignItems="center"
      height="100vh"
      color="white"
    >
      <Box textAlign="left" width="50%" ml="15ch">
        <Heading as="h1" size="3xl" mb={8} fontFamily="Poppins" fontWeight="500">
          Liberalize your trading experiences
        </Heading>
        <Text fontSize="0.9rem" mb={16} fontFamily="Poppins" fontWeight="300" color="rgba(255, 255, 255, 0.7)">
          The Fully On-Chain Orderbook for All Your Innovative and Secure Financial Services
        </Text>
        <Stack direction="row" spacing={4} mb={16} justifyContent="left">
          <Button
            variant="outline"
            size="md"
            fontFamily="Poppins"
            fontWeight="300"
            color="white"
            borderColor="white"
            _hover={{ borderColor: "#1E88E5" }}
          >
            Start Trading
          </Button>
          <Button
            variant="outline"
            size="md"
            fontFamily="Poppins"
            fontWeight="300"
            color="white"
            borderColor="white"
            _hover={{ borderColor: "#1E88E5" }}
          >
            Portfolio
          </Button>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} className="stats">
          <GridItem className="stat">
            <Text fontSize="1.5rem" fontFamily="Poppins" color="rgba(255, 255, 255, 0.7)" mb={1}>
              Total Trading Volume
            </Text>
            <Text fontSize="1rem" fontFamily="Poppins" color="#9EC2FF">
              $123,456,789
            </Text>
          </GridItem>
          <GridItem className="stat">
            <Text fontSize="1.5rem" fontFamily="Poppins" color="rgba(255, 255, 255, 0.7)" mb={1}>
              Total Deposited
            </Text>
            <Text fontSize="1rem" fontFamily="Poppins" color="#9EC2FF">
              $123,456,789
            </Text>
          </GridItem>
          <GridItem className="stat">
            <Text fontSize="1.5rem" fontFamily="Poppins" color="rgba(255, 255, 255, 0.7)" mb={1}>
              Tokens
            </Text>
            <Text fontSize="1rem" fontFamily="Poppins" color="#9EC2FF">
              99+
            </Text>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Wording;