import React from 'react';
import { Box, Flex, Heading, Text, Grid, GridItem, Image, Link } from '@chakra-ui/react';

const tradingData = [
  {
    icon: '/icons/BTC.svg',
    name: 'Bitcoin',
    symbol: 'BTC',
    price: '$27,359.52',
    volume: '$531,875.52M',
    change: '+10.81%',
    changeColor: 'green.400',
  },
  {
    icon: '/icons/ethereum-logo.svg',
    name: 'Ethereum',
    symbol: 'ETH',
    price: '$1,759.08',
    volume: '$331,875.52M',
    change: '+10.81%',
    changeColor: 'green.400',
  },
  {
    icon: '/icons/BNB.svg',
    name: 'Binance',
    symbol: 'BNB',
    price: '$27,359.52',
    volume: '$227,875.52M',
    change: '+2.31%',
    changeColor: 'green.400',
  },
  {
    icon: '/icons/SOL.svg',
    name: 'Solana',
    symbol: 'SOL',
    price: '$22.37',
    volume: '$531,875.52M',
    change: '-14.87%',
    changeColor: 'red.400',
  },
  {
    icon: '/icons/XRP.svg',
    name: 'Ripple',
    symbol: 'XRP',
    price: '$0.38',
    volume: '$531,875.52M',
    change: '+10.81%',
    changeColor: 'green.400',
  },
  {
    icon: '/icons/ADA.svg',
    name: 'Cardano',
    symbol: 'ADA',
    price: '$0.4',
    volume: '$531,875.52M',
    change: '+10.81%',
    changeColor: 'green.400',
  },
];

const TradingJourney = () => {
  return (
    <Box bg="#03091C" color="white" py={8} px={4}>
      <Heading as="h2" size="lg" mb={10} textAlign="center" fontFamily="Poppins">
        Begin your trading journey
      </Heading>
      <Box mx="auto" maxW="800px" w="90%" >
        <Grid templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} gap={4} w="100%" h="auto">
          {tradingData.map((data, index) => (
            <GridItem
              key={index}
              bg="#091439"
              borderRadius="md"
              p={4}
              h="120px"
              border="1px solid #333"
              textAlign="center"
            >

              <Flex justifyContent="space-around" alignItems="center" mb={2}>
                <Flex>
                  <Image src={data.icon} alt={data.name} boxSize="40px" mr={2} />
                </Flex>
                <Box>
                  <Flex>
                    <Heading as="h3" size="sm" fontFamily="Poppins">
                      {data.name} / <Text as="span" fontWeight="normal" color="gray.400">{data.symbol}</Text>
                    </Heading>
                  </Flex>
                  <Flex>
                    <Text fontSize={{ base: 'md', md: 'xl' }} fontWeight="bold" mb={1} fontFamily="Poppins">
                      {data.price}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex justifyContent="space-around" alignItems="center" mb={2} fontFamily="Poppins">
                <Text fontSize="xs" color="gray.400">
                  Vol. {data.volume}
                </Text>
                <Text fontSize="xs" color={data.changeColor} fontFamily="Poppins">
                  Chg. {data.change}
                </Text>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Box>
      <Flex justifyContent="center" mt={10}>
        <Link color="rgba(158, 202, 255, 0.8)" href="#explore-more">
          Explore more markets &gt;&gt;
        </Link>
      </Flex>
    </Box>
  );
};

export default TradingJourney;